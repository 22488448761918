<template>
    <v-container grid-list-xl class="elevation-2" color="red" rounded>

        <v-text-field :label='$t("Edite")' v-model="valueToEdit" v-on:keyup.enter="edit" v-if="editMode">
            <template v-slot:append-outer>
                <v-btn color="warning" small @click="edit" v-if="$vuetify.breakpoint.smAndUp">
                    <v-icon dark left small>edit</v-icon>
                    <template>{{ $t("Editar") }}</template>
                </v-btn>
                <v-btn color="warning" fab @click="edit" small v-else>
                    <v-icon>edit</v-icon>
                </v-btn>
            </template>
        </v-text-field>
        <v-text-field :label="`Digite aqui para adicionar ${itemTitle}`" v-model="valueToAdd" v-on:keyup.enter="add" v-else>
            <template v-slot:append-outer>
                <v-btn color="success" small @click="add" v-if="$vuetify.breakpoint.smAndUp">
                    <v-icon dark left small>add</v-icon>
                    <template>{{ $t("Adicionar") }}</template>
                </v-btn>
                <v-btn color="success" fab @click="add" small v-else>
                    <v-icon>add</v-icon>
                </v-btn>
            </template>
        </v-text-field>


        <v-simple-table fixed-header height="200px" v-if="list && list.length > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            {{ itemTitle }}
                        </th>
                        <th class="text-left">
                            {{ $t("Ações") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="( item, i ) in list " :key="i">
                        <td>{{ item }}</td>
                        <td>
                            <v-btn color="warning" fab @click="editItem(i)" x-small :disabled="editMode">
                                <v-icon>edit</v-icon>
                            </v-btn>
                            <v-btn color="error" class="ml-2" fab @click="deleteItem(i)" x-small :disabled="editMode">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>

<script>
export default {
    name: "SimpleListAdd",

    props: {
        list: {
            type: Array,
            required: true,
        },
        itemTitle: {
            type: String,
            default: "Nome",
        },
    },

    data() {
        return {
            editMode: false,
            valueToAdd: null,
            valueToEdit: null,
        };
    },

    methods: {
        add() {
            this.list.push(this.valueToAdd);
            this.valueToAdd = null;
        },
        edit() {
            this.list.push(this.valueToEdit);
            this.valueToEdit = null;
            this.editMode = false;
        },
        deleteItem(i) {
            this.list.splice(i, 1);
            this.$eventHub.$emit("msgError", this.$t("Deletado com sucesso."));
        },
        editItem(i) {
            this.valueToEdit = this.list[i];
            this.list.splice(i, 1);
            this.editMode = true;
        }

    },

    mounted() {
    },
};
</script>